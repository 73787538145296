import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';

import {
  ImageItem,
  LoadingImages,
} from '../../components/LoadingImages/LoadingImages';
import AppointmentWithoutTopSlots from './AppointmentWithoutTopSlots';
import AppointmentWithTopSlots from './AppointmentWithTopSlots';
import Alert from '../../components/Alert/Alert';
import BackButton from '../../components/BackButton';

import { useTopSlots } from './useTopSlots';
import { useFormatting } from '../../services/hooks/useFormatting';
import { getMinimumStartDateForTicketFromNow } from '../../helpers/TicketHelpers';
import DateTime from '../../helpers/DateTime';
import usePageTitle from '../../services/hooks/usePageTitle';
import styles from './Appointment.module.scss';

interface AppointmentProps {
  title?: string;
}

const Appointment: FC<AppointmentProps> = ({ title }) => {
  const { t } = useTranslation();

  usePageTitle(t('pageTitle:appointment'));

  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);
  const [isSlotBooking, setIsSlotBooking] = useState(false);
  const loadingImages = useMemo<ImageItem[]>(
    () => [
      {
        image: 'partners',
        text: t('appointmentLoading:selectingBestExpert'),
        ariaLabel: t('appointmentLoading:selectingBestExpert'),
      },
      {
        image: 'routes',
        text: t('appointmentLoading:calculatingEcoRoute'),
        ariaLabel: t('appointmentLoading:calculatingEcoRoute'),
      },
    ],
    [t]
  );

  const { formattedText: leadText } = useFormatting(
    stylesheet?.appointmentSection.leadTextBlock.value,
    {
      replaceDate: ticket?.workOrder.appointment.firstPossiblePlanDate,
    }
  );

  const { formattedText: reasonWeDontShowEarlierSlots } = useFormatting(
    stylesheet?.appointmentSection.reasonWeDontShowEarlierSlots,
    {
      replaceDate: ticket?.workOrder.appointment.earliestSchedulingDate,
    }
  );

  const { formattedText: lowAvailabilityText } = useFormatting(
    stylesheet?.lowAvailabilityMessage?.value
  );
  const lowAvailabilityAlert = lowAvailabilityText && (
    <Alert text={lowAvailabilityText} level="warn" white />
  );

  // These 2 values store timeframe that we ask a service to request data for.
  // When we want to get more data, we adjust minDate and allow a service request
  // data for as many weeks as needed starting from the minDate
  const [{ minDate, maxDate }, setMinMaxDates] = useState({
    // TODO: remove both conditions ` || Date.now()` when we always have a ticket and stylesheet
    minDate: getMinimumStartDateForTicketFromNow(
      new Date(
        ticket?.workOrder.appointment.firstPossiblePlanDate || Date.now()
      ),
      ticket?.workOrder.appointment.allowSameDayScheduling || false
    ),
    maxDate: new Date(
      ticket?.workOrder.appointment.latestPermittedPlanDate || Date.now()
    ),
  });

  const planDate = ticket?.workOrder.appointment.earliestSchedulingDate;
  const isPlanDateInFuture = planDate
    ? new Date(planDate) > DateTime.getDateXDaysInTheFuture(5)
    : false;

  const {
    isLoading,
    isNoSlotsLoading,
    initialRequestCompleted,
    topSlots,
    noTopSlots,
    highestSlot,
    availableDateStart,
    availableDateEnd,
    refetchNoTopSlots,
  } = useTopSlots(minDate, maxDate);

  const canRetrieveMore = availableDateEnd < maxDate;

  const onLoadMore = useCallback((): void => {
    const newMinDate = DateTime.cloneDate(availableDateEnd);
    setMinMaxDates({
      minDate: newMinDate,
      maxDate,
    });
  }, [availableDateEnd, maxDate]);

  if (!stylesheet || !ticket) {
    throw new Error('No stylesheet or ticket loaded');
  }

  const pageTitle = title ? title : t('appointment:title');

  if (
    !initialRequestCompleted ||
    (!Object.keys(topSlots).length && isNoSlotsLoading)
  ) {
    return (
      <div>
        <h1>{pageTitle}</h1>
        <LoadingImages items={loadingImages} />
      </div>
    );
  }

  return (
    <div>
      {Object.keys(topSlots).length ? (
        <>
          {!isSlotBooking && (
            <>
              <div className={styles.content}>
                <BackButton text={t('appointment:goBack')} />
              </div>
              <h1>{pageTitle}</h1>
              <p dangerouslySetInnerHTML={{ __html: leadText }} />
              {lowAvailabilityAlert}
            </>
          )}
          {!!reasonWeDontShowEarlierSlots && !!isPlanDateInFuture && (
            <Alert text={reasonWeDontShowEarlierSlots} level="info" white />
          )}
          <AppointmentWithTopSlots
            stylesheet={stylesheet}
            ticket={ticket}
            topSlots={topSlots}
            minDate={availableDateStart}
            maxDate={availableDateEnd}
            highestSlot={highestSlot}
            areLoadingSlots={isLoading}
            onLoadMore={onLoadMore}
            onSlotBooking={setIsSlotBooking}
            canRetrieveMore={canRetrieveMore}
          />
        </>
      ) : (
        <AppointmentWithoutTopSlots
          noTopSLots={noTopSlots}
          ticket={ticket}
          stylesheet={stylesheet}
          lowAvailabilityAlert={lowAvailabilityAlert}
          refetchNoTopSlots={refetchNoTopSlots}
        />
      )}
    </div>
  );
};

export default Appointment;
