import { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Error.module.scss';
import { LoadingContext } from '../../contexts/LoaderContext';
import { getErrorTitle, SupportedErrorTypes } from '../../services/ErrorService';
import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import MaintenanceError from './MaintenanceError';
import SquareAnimatedImage from '../../components/SquareAnimatedImage/SquareAnimatedImage';

import usePageTitle from '../../services/hooks/usePageTitle';
import StylesheetService from '../../services/StylesheetService';

const Error: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { setIsLoading } = useContext(LoadingContext);
  const { setIsInErrorMode } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);

  const { setStylesheets } = useContext(StylesheetContext);
  const { t } = useTranslation();

  usePageTitle(t('pageTitle:error'));

  useEffect(() => {
    setIsInErrorMode(true);
    if (!stylesheet) {
      new StylesheetService().getStylesheet(import.meta.env.VITE_GLOBAL_STYLESHEET!)
        .then(stylesheet => {
          setStylesheets(stylesheet);
          setIsLoading(false);
        });
    }
  }, [stylesheet, setIsLoading, setIsInErrorMode, setStylesheets]);

  const showMaintenanceError = id === SupportedErrorTypes.maintenance && stylesheet;
  const showExpirationParagraph = id === SupportedErrorTypes.noSignatureFound;

  if (!stylesheet) {
    return null;
  }

  return (
    <div className={styles.error}>
      {showMaintenanceError ? (
        <MaintenanceError maintenance={stylesheet!.maintenance} />
      ) : (
        <div className={styles.container}>
          <SquareAnimatedImage image={{ type: 'svg', name: 'error' }} ariaLabel="Error" />
          <h2>{getErrorTitle(t, id)}</h2>

          {showExpirationParagraph && <p className={styles.expiredParagraph}>{t('errorMessages:signatureNotFoundParagraph')}</p>}
        </div>
      )}
    </div>
  );
};

export default Error;
