import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import WhatsAppLink from '../../components/WhatsAppLink/WhatsAppLink';
import { BusinessHours, Stylesheet } from '../../interfaces/Stylesheet';
import { Separator } from '../../components/Separator/Separator';
import styles from './PhoneTab.module.scss';
import CallButton from '../../components/CallButton/CallButton';

interface PhoneTabProps {
  stylesheet: Stylesheet;
}

const days: (keyof BusinessHours)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const PhoneTab: FC<PhoneTabProps> = ({ stylesheet }) => {
  const { serviceDeskNumber, whatsappNumber, businessHours } = stylesheet.contact;
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Separator />
      <div>
        <h3>{t('contact:businessHours')}</h3>
        <table>
          <tbody>
            {days.map((day) => (
              <tr key={day}>
                <td>{t(`days:${day}`)}</td>
                {!businessHours?.[day]
                  ? (<td>{t('shared:closed')}</td>)
                  : (<td>{businessHours[day]}</td>)
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Separator />
      {!!whatsappNumber && (
        <div>
          <h3>{t('contact:chatWithWhatsApp')}</h3>
          <WhatsAppLink contactNumber={whatsappNumber} />
        </div>
      )}
      <Separator />
      <div>
        <h3>{t('footer:call')}</h3>
        <CallButton phoneNumber={serviceDeskNumber} />
      </div>
    </div>
  );
};

export default PhoneTab;
