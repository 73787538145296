import { FC, useState } from 'react';

import TabButton from './TabButton';
import { IconNames } from '../MaterialIcon/MaterialIcon';
import styles from './TabNavigator.module.scss';
import { Separator } from '../Separator/Separator';


export interface Tab {
  id: string;
  tabButton: {
    text: string;
    icon: IconNames;
  };
  content: JSX.Element;
}

interface TabNavigatorProps {
  tabs: Tab[];
}

const TabNavigator: FC<TabNavigatorProps> = ({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState({ id: tabs[0]?.id, tabIndex: 0 });

  if (!tabs.length) {
    return null;
  }

  return (
    <div className={styles.tabsContainer}>
      <Separator />
      <div className={styles.tabBtnList}>
        {tabs.length && tabs.map((tab, index) => (
          <TabButton
            key={tab.id}
            text={tab.tabButton.text}
            icon={tab.tabButton.icon}
            selected={selectedTab.id === tab.id}
            onClick={() => setSelectedTab({ id: tab.id, tabIndex: index })}
          />
        ))}
      </div>
      <div className={styles.tabContent}>
        {tabs[selectedTab.tabIndex].content}
      </div>
    </div>
  );
};

export default TabNavigator;
