import { FC } from 'react';
import classNames from 'classnames';

import MaterialIcon, { IconNames } from '../MaterialIcon/MaterialIcon';
import styles from './TabButton.module.scss';


interface TabButtonProps {
  text: string;
  ariaLabel?: string;
  selected: boolean;
  icon: IconNames;
  onClick: () => void;
}

const TAB_ICON_SIZE = 16;

const TabButton: FC<TabButtonProps> = ({ selected, onClick, text, icon, ariaLabel }) => {
  const tabButtonStyle = classNames(styles.tabBtn, {
    'partner-initial-border-color': selected,
    [styles.disabled]: !selected,
  });
  const tabIconStyle = classNames(!selected && styles.tabIcon);

  return (
    <div className={tabButtonStyle} role="button">
      <button
        onClick={selected ? undefined : onClick}
        aria-label={ariaLabel}
      >
        <MaterialIcon className={tabIconStyle} size={TAB_ICON_SIZE} name={icon} />
        <span>{text}</span>
      </button>
    </div>
  );
};

export default TabButton;
