import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './Cancel.module.scss';
import Button from '../../components/Button';
import Alert from '../../components/Alert/Alert';
import { StepsUpdateContext } from '../../components/Steps/StepsContext';

import { TicketContext } from '../../contexts/TicketContext';
import { StylesheetContext } from '../../contexts/StylesheetContext';

import { useFormatting } from '../../services/hooks/useFormatting';
import { sendCancelAppointmentClicked } from '../../services/Analytics';
import { usePost } from '../../helpers/hooks/usePost';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';
import { getConfirmStatuses } from '../../helpers/TicketHelpers';
import usePageTitle from '../../services/hooks/usePageTitle';
import TicketService from '../../services/TicketService';


const Cancel: FC = () => {
  const { ticket } = useContext(TicketContext);
  const { stylesheet } = useContext(StylesheetContext);
  const { navigateToActiveStep } = useContext(StepsUpdateContext);
  const { formattedText: cancelSectionText } = useFormatting(stylesheet?.cancelSection.textBlock.value);
  const { response: { pending, complete, error }, doRequest } = usePost();
  const success = complete && !error;
  const { t } = useTranslation();
  const navigate = useNavigate();

  useScrollToTop();

  usePageTitle(t('pageTitle:cancel'));

  if (!stylesheet || !ticket) {
    throw new Error('No stylesheet or ticket loaded');
  }

  const doCancelAppointment = useCallback(() => {
    sendCancelAppointmentClicked();
    doRequest(new TicketService().cancelTicket(ticket.workOrder.appointment.id));
  }, [doRequest, ticket.workOrder.appointment.id]);

  const handleExternalNavigation = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const isConfirmStatus = getConfirmStatuses().includes(ticket.workOrder.appointment.status);
  const { settings: { partnerName, partnerUrl } } = stylesheet;

  return (
    <div className={styles.cancelRoot}>
      <div className={styles.innerContainer}>
        <h1>{success ? t('cancel:titleSuccess') : t('cancel:title')}</h1>

        {!success && (
          isConfirmStatus ? (
            <p>{t('cancel:description', { partner: partnerName })}</p>
          ) : (
            <p>{cancelSectionText}</p>
          )
        )}

        {(!isConfirmStatus || success) && <Alert
          white
          level="info"
          icon={!success ? 'calendar_today' : undefined}
          className={styles.alert}
          text={!success ? t('cancel:makeAppointment') : t('cancel:makeNewAppointment')}
          textButton={{
            text: !success
              ? t('cancel:makeAppointmentLink')
              : t('cancel:contactPartner', { partner: partnerName }),
            onClick: () => {
              !success ? navigate(`/t/${ticket.hashId}`) : handleExternalNavigation(partnerUrl);
            },
          }}
        />}

        {!!error && (
          <Alert
            level="error"
            text={t('cancel:errorMessage')}
          />
        )}
      </div>

      {!success && (
        <div className={styles.buttonContainer}>
          <Button
            disabled={pending}
            onClick={doCancelAppointment}
            text={t('button:cancelAppointment')}
            ariaLabel={t('button:cancelAppointment')}
          />
          <Button
            variant="text"
            onClick={navigateToActiveStep}
            text={t('button:abortCancelAppointment')}
            ariaLabel={t('button:abortCancelAppointment')}
          />
        </div>
      )}
    </div>
  );
};

export default Cancel;
