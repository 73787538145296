import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import style from './PreFooter.module.scss';
import BusinessReviews from '../BusinessReviews/BusinessReviews';
import WhatsAppLink from '../WhatsAppLink/WhatsAppLink';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { Stylesheet } from '../../interfaces/Stylesheet';
import { Separator } from '../Separator/Separator';
import CallButton from '../CallButton/CallButton';
import ContentfulService from '../../services/ContentfulService';

interface PreFooterProps {
  stylesheet: Stylesheet;
}

const ICON_SIZE = 20;

const PreFooterComponent: FC<PreFooterProps> = ({ stylesheet }) => {
  const { t } = useTranslation();
  const { contact } = stylesheet;

  const contactFormEnabled = !ContentfulService.getContentfulValueWithDefault(contact.disabled, false);
  const showPhoneNumber = ContentfulService.getContentfulValueWithDefault(contact.showPhoneNumberInFooter, true);

  return (
    <div className={style.container}>
      <div className={style.titleSectionWrapper}>
        <div>
          <h2>{t('footer:title')}<br /> <span className={style.titleHighlight}>Guidion</span>.</h2>
        </div>

        <Separator classes={classNames(style.permanentSeparator, style.firstSeparator)} />

        <div className={style.businessReviews}>
          <BusinessReviews />
        </div>
      </div>

      <div>
        <h2>{t('footer:questions')}</h2>
        <div className={style.contactOptions}>
          {!!contact.whatsappNumber && (
            <>
              <div>
                <h3>{t('contact:chatWithWhatsApp')}</h3>
                <WhatsAppLink contactNumber={contact.whatsappNumber} />
              </div>


              {(showPhoneNumber || contactFormEnabled) && <Separator classes={style.mobileOnlySeparator} />}
            </>
          )}
          {showPhoneNumber && (
            <>
              <div>
                <h3>{t('footer:call')}</h3>
                <CallButton phoneNumber={contact.serviceDeskNumber} />
              </div>

              {contactFormEnabled && <Separator classes={style.mobileOnlySeparator} />}
            </>
          )}

          {contactFormEnabled && (
            <div>
              <h3>{t('footer:sendEmail')}</h3>
              <span className={style.footerSectionItem}>
                <MaterialIcon name="wysiwyg" size={ICON_SIZE} />
                <Link to="/contact">{t('footer:contactForm')}</Link>
              </span>
            </div>
          )}

        </div>

        <Separator classes={style.permanentSeparator} />

        <div>
          <h3 className={style.questionsTitle}>{t('footer:otherQuestions')}</h3>
          <Link to="/faq">{t('footer:faq')}</Link>
        </div>

        <Separator classes={style.permanentSeparator} />
      </div>
    </div>
  );
};

export const PreFooter = React.memo(PreFooterComponent);
