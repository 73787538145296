import React, { FC } from 'react';

import Step from './Step';
import { StepsContext } from './StepsContext';
import style from './Steps.module.scss';

const Steps: FC = () => {
  const { steps } = React.useContext(StepsContext);

  return (
    <nav className={style.steps}>
      <ul>
        {steps.map((step, i) => (
          <Step
            key={step.id}
            step={step}
            index={i + 1}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Steps;
