export default {
  nl: {
    footer: {
      title: 'Graag gedaan,',
      questions: 'Vragen over je afspraak?',
      otherQuestions: 'Andere vragen?',
      faq: 'Bekijk de veelgestelde vragen',
      businessReviews: '{{value, number(minimumFractionDigits: 0)}} Google Reviews',
      call: 'Bel Guidion',
      sendEmail: 'Liever via de mail?',
      contactForm: 'Contactformulier',
      terms: 'Guidion handelt ter uitvoering van de overeenkomst van {{partner}} en is hierbij geen contractpartij. Vind [a="{{link}}"]hier[/a] de algemene voorwaarden van {{partner}}.',
      cookieStatement: 'Cookie verklaring',
    }
  },
  en: {
    footer: {
      title: 'Graag gedaan,',
      questions: 'Questions about your appointment?',
      otherQuestions: 'Other questions?',
      faq: 'See the frequently asked questions',
      businessReviews: '{{value, number(minimumFractionDigits: 0)}} Google Reviews',
      call: 'Call Guidion',
      sendEmail: 'Prefer to send an email?',
      contactForm: 'Contact form',
      terms: 'Guidion only executes the agreement between you and {{partner}} and is not a contracting party to this. The general terms and conditions of {{partner}} can be found [a="{{link}}"]here[/a].',
      cookieStatement: 'Cookie statement',
    }
  }
};
