import { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import style from './Footer.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TermsAndConditions } from '../TermsAndConditions/TermsAndConditions';
import { PreFooter } from '../PreFooter/PreFooter';

const Footer: FC = (): JSX.Element | null => {
  const { stylesheet } = useContext(StylesheetContext);
  const { pathname } = useLocation();
  const shouldHideFooter = pathname === '/contact';

  if (!stylesheet || shouldHideFooter) {
    return null;
  }

  const { settings } = stylesheet;
  const pagesWithWhiteBackground = ['/customer', '/contact'];
  const wraperClass = classNames(style.wrapper, !pagesWithWhiteBackground.includes(pathname) && style.withBorderRadius);

  return (
    <footer>
      <div className={wraperClass}>
        <div className="container">
          <PreFooter stylesheet={stylesheet} />
          <TermsAndConditions partner={settings.partnerName} link={settings.termsUrl} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
