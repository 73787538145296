import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import style from './WhatsAppLink.module.scss';
import { TicketContext } from '../../contexts/TicketContext';


interface WhatsAppLinkProps {
  contactNumber: string;
}

const WhatsAppLink: FC<WhatsAppLinkProps> = ({ contactNumber }): JSX.Element | null => {
  const { t } = useTranslation();
  const { ticket } = useContext(TicketContext);

  if (!ticket) {
    return null;
  }

  const emptySpaceCondition = /\s/g;

  // In case there is a misconfigurion on backend this removes spaces between numbers if present
  const formattedContactNumber = contactNumber.replace(emptySpaceCondition, '');

  // This regex formats numbers from +31XXXXXXXXX to +31 XX XXX XXXX
  const formattedPhoneNumber = formattedContactNumber.replace(/^(\+31)(6[\d]{1}|[\d]{2})([\d]{3})([\d]{4})$/, '$1 $2 $3 $4');


  return (
    <span className={style.phoneItem}>
      <img alt="whatsapp" src="/assets/whatsapp.svg" />
      <a
        aria-label={t('contact:chatWithWhatsApp')}
        href={`https://wa.me/${formattedContactNumber}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {formattedPhoneNumber}
      </a>
    </span>
  );
};

export default WhatsAppLink;
