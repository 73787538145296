import { StepId, StepModel } from '../components/Steps/interfaces/Step';
import { getConfirmStatuses } from '../helpers/TicketHelpers';
import { Ticket } from '../interfaces/Ticket';

const getCustomerStep = (isAtConfirmation: boolean, isAtScheduling: boolean): StepModel => ({
  id: StepId.customer,
  isDisabled: isAtConfirmation,
  isCompleted: isAtScheduling || isAtConfirmation,
  uri: '/' + StepId.customer
});

const getQuestionsStep = (isAtConfirmation: boolean, isAtScheduling: boolean): StepModel => ({
  id: StepId.questions,
  isDisabled: !isAtScheduling,
  isCompleted: isAtScheduling || isAtConfirmation,
  uri: '/' + StepId.questions
});

const getAppointmentStep = (isNewTicket: boolean, isAtConfirmation: boolean): StepModel => ({
  id: StepId.appointment,
  isDisabled: isNewTicket || isAtConfirmation,
  isCompleted: isAtConfirmation,
  uri: '/' + StepId.appointment
});

const getChangeStep = (isAtConfirmation: boolean): StepModel => ({
  id: StepId.change,
  isDisabled: isAtConfirmation,
  isCompleted: isAtConfirmation,
  uri: '/' + StepId.change
});

const getConfirmStep = (isAtConfirmation: boolean): StepModel => ({
  id: StepId.confirm,
  isDisabled: !isAtConfirmation,
  isCompleted: isAtConfirmation,
  uri: '/' + StepId.confirm
});

const confirmationStatuses = getConfirmStatuses();

const checkIsNewTicket = (ticket: Ticket) => {
  const { workOrder } = ticket;
  const { isQuestionnaireCompleted, status: workOrderStatus, appointment } = workOrder;
  const { isSchedulable } = appointment;
  const blocked = !isSchedulable;

  return ((workOrderStatus === 'New') || !workOrderStatus) && (!isQuestionnaireCompleted || blocked);
};

const checkAppointmentIsAtScheduling = (ticket: Ticket, isNewTicket: boolean) => {
  const { workOrder } = ticket;
  const { isQuestionnaireCompleted, appointment } = workOrder;
  const { status, arrivalWindowStart } = appointment;

  return !isNewTicket && (!arrivalWindowStart && !!isQuestionnaireCompleted)
    || (!!arrivalWindowStart && !confirmationStatuses.includes(status));
};

const getDefaultSteps = (ticket?: Ticket, isInErrorMode?: boolean): StepModel[] => {
  if (isInErrorMode) {
    return getErrorModeSteps();
  }

  if (!ticket) return [];

  const { status } = ticket.workOrder.appointment;
  const isNewTicket = checkIsNewTicket(ticket);
  const isAtScheduling = checkAppointmentIsAtScheduling(ticket, isNewTicket);

  const isAtConfirmation = (!isNewTicket && !isAtScheduling) || confirmationStatuses.includes(status);

  return [
    getCustomerStep(isAtConfirmation, isAtScheduling),
    getQuestionsStep(isAtConfirmation, isAtScheduling),
    getThirdStep(ticket.workOrder.appointment.allowRescheduling, isNewTicket, isAtConfirmation),
    getConfirmStep(isAtConfirmation)
  ];
};

const getThirdStep = (allowRescheduling: boolean, isNewTicket: boolean, isAtConfirmation: boolean) => {
  return allowRescheduling
    ? getChangeStep(isAtConfirmation)
    : getAppointmentStep(isNewTicket, isAtConfirmation);
};

const getErrorModeSteps = () => {
  return getStepsAs([
    getCustomerStep(false, false),
    getQuestionsStep(false, false),
    getAppointmentStep(false, false),
    getConfirmStep(false)
  ], { isDisabled: true, isCompleted: false });
};

const getActiveStep = (steps: StepModel[]): StepModel => {
  const uncompletedSteps = steps.filter(step => !step.isCompleted);
  const areAllCompleted = uncompletedSteps.length === 0;
  const lastStep = steps[steps.length - 1];
  const activeStep = areAllCompleted ? lastStep : steps.find(step => !step.isDisabled && !step.isCompleted);

  return activeStep || steps[0];
};

type GetStepsAsOptions = Partial<Pick<StepModel, 'isCompleted' | 'isDisabled'>>;

const getStepsAs = (steps: StepModel[], options: GetStepsAsOptions): StepModel[] => {
  return steps.map(step => ({ ...step, ...options }));
};

const showSteps = (pathName: string) => {
  const pathsToHide = ['/sign', '/error', '/confirm', '/cancel'];

  return !pathsToHide.some(pathToHide => pathName.startsWith(pathToHide));
};

const showStepperBackground = (pathName: string) => {
  const pathsToHide = ['/contact'];

  return showSteps(pathName) && !pathsToHide.some(pathToHide => pathName.startsWith(pathToHide));
};

export default {
  getDefaultSteps,
  getActiveStep,
  getStepsAs,
  showSteps,
  showStepperBackground,
};
