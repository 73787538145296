import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CallButton.module.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { Link } from 'react-router-dom';


interface CallButtonProps {
  phoneNumber: string;
}

const CallButton: FC<CallButtonProps> = ({ phoneNumber }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <span className={styles.phoneItem}>
      <MaterialIcon name="phone" size={20} />
      <p>
        <Link to={`tel:${phoneNumber}`}>{phoneNumber}</Link>
        <span className={styles.localTariff}> ({t('contact:localTariff')})</span>
      </p>
    </span>
  );
};

export default CallButton;
