export default {
  nl: {
    contact: {
      title: 'Hoe kunnen\nwe <span>helpen</span>?',
      faq: 'Veelgestelde vragen',
      faqLink: 'Bekijk ook onze veelgestelde vragen',
      mailTabTitle: 'Mailen',
      callTabTitle: 'Bellen',
      businessHours: 'Klantenservice openingstijden',
      invalidEmail: 'Ongeldig e-mailadres',
      loadingMessage: 'Bericht wordt verzonden ...',
      errorMessage: 'Het verzenden van het bericht is mislukt. Probeer het nogmaals of neem telefonisch contact met ons op.',
      successMessage: 'Bericht is succesvol verzonden.',
      chatWithWhatsApp: 'Chat met Guidion via WhatsApp',
      localTariff: 'lokaal tarief',
    }
  },
  en: {
    contact: {
      title: 'How can\nwe <span>help</span>?',
      faq: 'Frequently Asked Questions',
      faqLink: 'Also view our frequently asked questions',
      mailTabTitle: 'Mail',
      callTabTitle: 'Call',
      businessHours: 'Customer service opening hours',
      invalidEmail: 'Invalid e-mailaddress',
      loadingMessage: 'Message is being sent ...',
      errorMessage: 'Failed to send the message. Please try again or contact us by phone.',
      successMessage: 'Message was successfully sent',
      chatWithWhatsApp: 'Chat with Guidion via WhatsApp',
      localTariff: 'local tariff',
    }
  }
};
