import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Contact.module.scss';
import { StylesheetContext } from '../../contexts/StylesheetContext';
import { TicketContext } from '../../contexts/TicketContext';
import { Separator } from '../../components/Separator/Separator';
import TabNavigator, { Tab } from '../../components/TabNavigator/TabNavigator';
import PhoneTab from './PhoneTab';
import ContactForm from './ContactForm';
import BackButton from '../../components/BackButton';

import usePageTitle from '../../services/hooks/usePageTitle';
import { useScrollToTop } from '../../helpers/hooks/useScrollToTop';
import { useDocumentBodyStyle } from '../../helpers/hooks/useDocumentBodyStyle';

const ContactPage: FC = () => {
  const { stylesheet } = useContext(StylesheetContext);
  const { ticket } = useContext(TicketContext);

  const { t } = useTranslation();

  usePageTitle(t('pageTitle:contact'));
  useDocumentBodyStyle({ backgroundColor: '#FFFFFF' });
  useScrollToTop();

  if (!stylesheet) {
    return null;
  }

  const tabs: Tab[] | null = ticket ? [{
    id: 'mail',
    tabButton: {
      text: t('contact:mailTabTitle'),
      icon: 'mail'
    },
    content: (<ContactForm ticket={ticket} />)
  },
  {
    id: 'call',
    tabButton: {
      text: t('contact:callTabTitle'),
      icon: 'phone'
    },
    content: (<PhoneTab stylesheet={stylesheet} />)
  }] : null;

  const imageContainerStyles = classNames('background-color', styles.imagesContainer);

  return (
    <div className={styles.container}>
      <div className={styles.contactContainer}>
        <BackButton text={t('button:back')} ariaLabel={t('button:back')} useRouter />
        <h1 dangerouslySetInnerHTML={{ __html: t('contact:title') }} />
        <Separator />
        <div>
          <h2>{t('contact:faq')}</h2>
          <Link className={styles.faqLink} to="/faq">
            {t('contact:faqLink')}
          </Link>
        </div>
        {tabs && <TabNavigator tabs={tabs} />}
      </div>
      <div className={imageContainerStyles}>
        <div className={styles.images}>
          <img src="/assets/technicians.png" alt={t('contactPageAlts:technicians')} />
          <img className={styles.office} src="/assets/office.png" alt={t('contactPageAlts:office')} />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
